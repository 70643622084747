<template>
  <Fieldset legend="วิเคราะห์ข้อมูล">
    <div class="p-grid">
      <div class="p-col-12 p-sm-6" v-for="(plot, idx) in images" :key="idx">
        <img :src="plots[plot]" style="width: 100%" />
      </div>
    </div>
  </Fieldset>
</template>

<script>
import axios from 'axios'
import { imageEncode } from '../../Utility'

export default {
  layout: 'Private',
  data() {
    return {
      plots: {
        plot1: null,
        plot2: null,
        plot3: null,
        plot4: null,
        plot5: null,
        plot6: null,
        plot7: null,
        plot8: null,
        plot9: null,
        plot10: null
      },
      images: [
        'plot1',
        'plot2',
        'plot3',
        'plot4',
        'plot5',
        'plot5',
        'plot5',
        'plot5',
        'plot5',
        'plot5'
      ]
    }
  },
  async mounted() {
    const { VUE_APP_ML_ENDPOINT } = process.env
    let delay = 500
    this.images.forEach((plot, idx) => {
      setTimeout(
        async () => {
          const { data: blob } = await axios.get(
            `${VUE_APP_ML_ENDPOINT}/${plot}.png`,
            {
              responseType: 'arraybuffer'
            }
          )
          this.plots[plot] = imageEncode(blob)
        },
        idx == 0 ? 0 : delay + idx * 500
      )
    })
  }
}
</script>

<style>
</style>